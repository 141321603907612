<template>
  <v-container  fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-row>
        <v-col class="mt-0 pt-0 pb-0 xs" cols="12" sm="12" md="12" lg="12">
          <v-card>
            <v-card-actions>
              <v-icon @click="close">mdi-arrow-left-circle</v-icon>

              <v-spacer></v-spacer>
              <v-btn color="primary"  @click="addBatch">Add batch</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-2" cols="12">
          <v-card>
            <v-card-text>
              <v-data-table
                :items="batches"
                :dense="true"
                :items-per-page-options= "[ 50]"
                :items-per-page="50"
                :headers='[ 
                 { text: "Name", value: "name" }, 
                { text: "Batch ID", value: "id" }, 
                  { text: "Extract type", value: "extractType" }, 
                
                  { text: "Status", value: "status" },                
                  { text: "Created on", value: "createdOn" },
                  { text: "Created by", value: "createdBy" },
                  { text: "Modified on", value: "modifiedOn" },
                  { text: "Modified by", value: "modifiedBy" },
                  { text: "", value: "actions" }
                ]'
                class="elevation-1 smallTextTable"
              >

               <template slot="item.name" slot-scope="props">
                  {{props.item.name}}<br/>
                 
                </template>
              <template slot="item.id" slot-scope="props">
                  {{props.item.id}}
                </template>
                <template slot="item.extractType" slot-scope="props">
                  {{props.item.extractType}}
                </template>
               
                <template slot="item.status" slot-scope="props">
                  <span :style="props.item.status=='Extracted'?'color:#156a59':''">{{props.item.status}}</span>
                </template>
                <template slot="item.createdOn" slot-scope="props">
                  <div>{{ moment(util.toDt(props.item.createdOn)).format("DD-MM-YYYY hh:mm")}}</div>
                </template>
                <template slot="item.createdBy" slot-scope="props">
                  <div>{{props.item.createdBy.firstName + " " + props.item.createdBy.lastName}}</div>
                </template>
                <template slot="item.modifiedOn" slot-scope="props">
                  <div>{{ moment(util.toDt(props.item.modifiedOn)).format("DD-MM-YYYY hh:mm")}}</div>
                </template>
                <template slot="item.modifiedBy" slot-scope="props">
                  <div>{{props.item.modifiedBy.firstName + " " + props.item.modifiedBy.lastName}}</div>
                </template>
                <template slot="item.actions" slot-scope="props">
                  <div>
                    <v-icon slot="activator" small class="mr-2" @click="editBatch(props.item)">mdi-pencil</v-icon>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import * as migrationService from "../../services/migrationService";
import moment from "moment";
export default {
  
  data: () => ({
    batches:[],
    util:null
  }),
  mounted() {
    this.init();
  },
  
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }

  },
  methods: {
    init() {
      this.util = this.$utils;
      this.getBatches();
    },
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    close() {
      this.$router.push("/invoicing-billing");      
    },
    getBatches(){
      const me=this;
      migrationService
        .listBatches(me.currentOrganisation.id)
        .then(result => {          
          result.docs.map(doc => {
            const batch = doc.data();
            batch.id = doc.id;
            me.batches.push(batch);
          });
        console.log(me.batches);
          this.batches = this.batches.sort((ax, bx) => {
            if (ax.modifiedOn == undefined) {
              return 1;
            }
            if (bx.modifiedOn == undefined) {
              return -1;
            }
            let a = ax.modifiedOn.toDate();
            let b = bx.modifiedOn.toDate();
            return a > b ? -1 : a < b ? 1 : 0;
          });
        });
    },
    addBatch(){
      this.$store.commit("setCurrentBatch", null);
      this.$router.push("/invoicing-billing/batch");
    },
    editBatch(item){
      this.vm = Object.assign({}, item);
      this.$store.commit("setCurrentBatch", item);
      this.$router.push("/invoicing-billing/batch");
    }
  }
};
</script>
