var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{staticClass:"mt-0 pt-0 pb-0 xs",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',[_c('v-card-actions',[_c('v-icon',{on:{"click":_vm.close}},[_vm._v("mdi-arrow-left-circle")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addBatch}},[_vm._v("Add batch")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-2",attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 smallTextTable",attrs:{"items":_vm.batches,"dense":true,"items-per-page-options":[ 50],"items-per-page":50,"headers":[ 
               { text: "Name", value: "name" }, 
              { text: "Batch ID", value: "id" }, 
                { text: "Extract type", value: "extractType" }, 
              
                { text: "Status", value: "status" },                
                { text: "Created on", value: "createdOn" },
                { text: "Created by", value: "createdBy" },
                { text: "Modified on", value: "modifiedOn" },
                { text: "Modified by", value: "modifiedBy" },
                { text: "", value: "actions" }
              ]},scopedSlots:_vm._u([{key:"item.name",fn:function(props){return [_vm._v(" "+_vm._s(props.item.name)),_c('br')]}},{key:"item.id",fn:function(props){return [_vm._v(" "+_vm._s(props.item.id)+" ")]}},{key:"item.extractType",fn:function(props){return [_vm._v(" "+_vm._s(props.item.extractType)+" ")]}},{key:"item.status",fn:function(props){return [_c('span',{style:(props.item.status=='Extracted'?'color:#156a59':'')},[_vm._v(_vm._s(props.item.status))])]}},{key:"item.createdOn",fn:function(props){return [_c('div',[_vm._v(_vm._s(_vm.moment(_vm.util.toDt(props.item.createdOn)).format("DD-MM-YYYY hh:mm")))])]}},{key:"item.createdBy",fn:function(props){return [_c('div',[_vm._v(_vm._s(props.item.createdBy.firstName + " " + props.item.createdBy.lastName))])]}},{key:"item.modifiedOn",fn:function(props){return [_c('div',[_vm._v(_vm._s(_vm.moment(_vm.util.toDt(props.item.modifiedOn)).format("DD-MM-YYYY hh:mm")))])]}},{key:"item.modifiedBy",fn:function(props){return [_c('div',[_vm._v(_vm._s(props.item.modifiedBy.firstName + " " + props.item.modifiedBy.lastName))])]}},{key:"item.actions",fn:function(props){return [_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"slot":"activator","small":""},on:{"click":function($event){return _vm.editBatch(props.item)}},slot:"activator"},[_vm._v("mdi-pencil")])],1)]}}])})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }